<template>
  <div class="main">
    <heads></heads>
    <ztflhbanners></ztflhbanners>
    <div class="content">
      <div class="cons">
        <div class="con-title">全部分类</div>
        <div class="con-nav">
          <div class="con-nav-link">
            <router-link to="/flh">中图分类号</router-link>
          </div>
          <span v-if="navlist.length>0">
            <div class="con-nav-link" v-for="(item, key) in navlist" :key="key" @click="clickxq(item.Id)">
              <span class="con-nav-linkxg">/</span>
              <a href="javascript:;">{{item.zname}}</a>
            </div>
          </span>
        </div>
        <div class="con-ul" v-if="alllist.length>0">
          <div class="con-list" v-for="(item, key) in alllist" :key="key" @click="clickxq(item.Id)">
            <div class="con-list-title">{{ item.zcode }}</div>
            <div class="con-list-text" :title="item.zname">
              {{ item.zname }}
            </div>
          </div>
        </div>
        <div class="con-tips" v-if="alllist.length==0">
          <div class="con-tips-wz">该分类号下没有更多内容</div>
          <el-button type="primary" @click="clickfh">返回全部分类</el-button>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

// 链接中的参数
let linkdata=router.currentRoute.value.query.id
// 导航
let navlist = reactive([]);
// 列表
let alllist = reactive([]);

// 获取列表
const getlist = (id) => {
  get("https://xk-api.yuanpingjia.cn/api/Ztflh/GetNextFlh", {
    Id:id,
  }).then((res) => {
    // console.log(res);
    navlist.length=0
    alllist.length=0
    navlist.push(...res.data.dhlist);
    alllist.push(...res.data.list);
    document.title = "首站-论文投稿智能助手|中图分类号查询|"+res.data.dhlist[res.data.dhlist.length-1].zname;
  });
};
getlist(linkdata);

// 查询下级
const clickxq=(id)=>{
  getlist(id);
}
 
// 返回
const clickfh=()=>{
  router.push({
    path: "/flh",
    query: {},
  });
}

</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.con-nav-link a:link,
.con-nav-link a:visited {
  text-decoration: none;
  color: #551a8b;
}
.con-nav-link a:hover {
  color: #409eff;
}
.con-nav:after {
  content: "";
  display: table;
  clear: both;
}
.con-nav-link {
  float: left;
  margin: 0 10px 0 0;
}
.con-nav-linkxg {
  padding: 0 10px 0 0;
}

.con-title {
  padding: 0 14px 20px 14px;
  font-size: 24px;
  font-weight: bold;
}
.con-nav {
  margin: 0 14px;
  text-align: justify;
  background: #e9ecef;
  padding: 15px 15px;
  border-radius: 4px;
  line-height: 24px;
}

.con-ul {
  margin: 20px 0 0 0;
}
.con-ul:after {
  content: "";
  display: table;
  clear: both;
}
.con-list {
  width: 350px;
  height: 80px;
  float: left;
  margin: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 10px;
  cursor: pointer;
}
.con-list:hover {
  background: #409eff;
  color: #fff;
}
.con-list-title {
  text-align: center;
  padding: 15px 0 5px 0;
}
.con-list-text {
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.con-tips{
  padding: 10px;
  color: #999;
}
.con-tips-wz{
  padding: 0 0 10px 0;
}
</style>
